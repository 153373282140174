import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PageBanner from "../components/common/PageBanner"
import Layout from "../components/navigation/Layout"
import { useForm } from "react-hook-form"
import * as qs from "query-string"
import axios, { AxiosRequestConfig } from "axios"

const NETLIFY_FORM_NAME = "SBG Partners Contact Form"

const ContactUs = props => {
  const { register, handleSubmit, reset: resetForm } = useForm()
  const [serverResponse, setServerResponse] = useState("")

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          author
          phoneNumber
          contactEmail
          address
        }
      }
    }
  `)

  const onSubmit = async data => {
    const finalFormData = {
      ...data,
      "form-name": NETLIFY_FORM_NAME,
    }
    const axiosOptions: AxiosRequestConfig = {
      url: props.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(finalFormData),
    }

    try {
      const resp = await axios(axiosOptions)
      resetForm()
      setServerResponse("Thank you. We will be in touch soon.")
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout>
      <PageBanner
        title=""
        backgroundImg="/img/backgrounds/headers/tombstone.jpg"
      />
      <div
        className="container"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <div className="row">
          {/* <div className="col-lg-8 sm-margin-30px-bottom">
            <div className="padding-50px-right sm-no-padding-right">
              <form
                name={NETLIFY_FORM_NAME}
                data-netlify="true"
                method="post"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        ref={register}
                        required
                        id="name"
                        name="name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        ref={register}
                        required
                        id="email"
                        type="email"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 margin-20px-bottom">
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        ref={register}
                        required
                        id="message"
                        name="message"
                        className="form-control line-height-70"
                      ></textarea>
                    </div>
                  </div>
                  {serverResponse && (
                    <div className="col-lg-12" style={{ paddingBottom: 25 }}>
                      {serverResponse}
                    </div>
                  )}
                  <div className="col-lg-12">
                    <div className="Send">
                      <button type="submit" className="butn">
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div> 
          </div>*/}

          <div className="col-lg-12">
            <div>
              <h4>Contact Details</h4>
              <p className="contact-title-text">
                Get in touch with us for any questions about our communities.
              </p>
            </div>
            <div className="d-flex align-items-center margin-20px-bottom">
              <div>
                <span className="ti-home font-size38 xs-font-size34 text-theme-color"></span>
              </div>
              <div className="margin-30px-left">
                <p className="no-margin-bottom text-extra-dark-gray font-weight-600">
                  Visit Us
                </p>
                <p className="no-margin-bottom">
                  8484 Wilshire Blvd, Suite 760
                </p>
                <p className="no-margin-bottom">Beverly Hills, CA 90211</p>
              </div>
            </div>
            <div className="d-flex align-items-center margin-20px-bottom">
              <div>
                <span className="ti-email font-size38 xs-font-size34 text-theme-color"></span>
              </div>
              <div className="margin-30px-left">
                <p className="no-margin-bottom text-extra-dark-gray font-weight-600">
                  Mail Us
                </p>
                <p className="no-margin-bottom width-90">
                  <a href={`mailto:${site.siteMetadata.contactEmail}`}>
                    {site.siteMetadata.contactEmail}
                  </a>
                </p>
              </div>
            </div>
            {/* <div className="d-flex align-items-center">
              <div>
                <span className="ti-mobile font-size38 xs-font-size34 text-theme-color"></span>
              </div>
              <div className="margin-30px-left">
                <p className="no-margin-bottom text-extra-dark-gray font-weight-600">
                  Call Us
                </p>
                <p className="no-margin-bottom">
                  <a href={`tel:${site.siteMetadata.phoneNumber}`}>
                    {site.siteMetadata.phoneNumber}
                  </a>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs
